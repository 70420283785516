import React, { Component, Fragment } from 'react';

import Immutable from 'immutable';

import { Map, List, Record } from 'immutable';


import { BrowserRouter, Routes, Route } from "react-router-dom";

import AllPage from './pages/AllPage'
import CheckPage from './pages/CheckPage'

import CheckPage2 from './pages/CheckPage2'
import CheckPageFailed from './pages/CheckPageFailed'
import CSVImport from './pages/CSVImport'

import Fashionsnap from './pages/fashionsnap';






class MainComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: Map({
                contact: List()
            })


        }
    }
    componentDidMount() {

    }

    render() {

        return (

            <BrowserRouter>

                <Routes>
                    <Route path="/fashionsnap" element={<Fashionsnap cognito={this.props.cognito} stage={this.props.stage} setStage={this.props.setStage} urls={this.props.urls} />} />
                    <Route exact path="/" element={<AllPage cognito={this.props.cognito} stage={this.props.stage} setStage={this.props.setStage} urls={this.props.urls} />} />
                    <Route path="/check" element={<CheckPage cognito={this.props.cognito} stage={this.props.stage} setStage={this.props.setStage} urls={this.props.urls} />} />
                    <Route path="/csv" element={<CSVImport cognito={this.props.cognito} stage={this.props.stage} setStage={this.props.setStage} urls={this.props.urls} />} />


                    <Route path="/check2" element={<CheckPage2 cognito={this.props.cognito} stage={this.props.stage} setStage={this.props.setStage} urls={this.props.urls} />} />
                    <Route path="/failed" element={<CheckPageFailed cognito={this.props.cognito} stage={this.props.stage} setStage={this.props.setStage} urls={this.props.urls} />} />


                </Routes>

            </BrowserRouter>

        );
    }
}

export default MainComponent;
