import React, { Component, Fragment } from "react";

import Immutable, { is } from "immutable";

import * as XLSX from 'xlsx';

import axios from "axios";

import { Table, Container, Row, InputGroup, Col, Navbar, NavbarBrand, Nav, NavItem, NavLink, Card, CardBody, CardTitle, CardSubtitle, CardText, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonGroup, FormGroup, Label, Input } from "reactstrap";
import moment from "moment";
import HeaderComponent from "../header/HeaderComponent";

import '../../css/ImageTable.css';
const { Base64 } = require("js-base64");


class CheckPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			project_name: "mtest",
			data: [],
			project_list: [],
			selectedProjectName: "",
			isModalOpen: false,
			modalImageSrc: '',
			isRejctModelOpen: false,
			rejectTargetIdx: undefined,
			rejectReason: "",
			currentRejectReason: "",
			excludeEvaluated: true,
			modalImageSrc2: '',
			leftCount: -1,

		};

		this.modalRef = React.createRef();
		window.that = this;
	}







	handleClickOutside = (event) => {
		if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
			this.closeModal();
		}
	};


	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}


	async componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
		this.getProjectList();
		//this.getImageData();

	}


	getProjectList = async () => {
		const result = await axios({
			method: "Get",
			url: `${process.env.REACT_APP_API_PATH}/dev/admin/project/list`,
			params: {


			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		const projects = JSON.parse(result.data.body).project_list;

		this.setState({ project_list: projects, selectedProjectName: projects[0].project_name }, () => {

			this.getImageData();

		});
	}
	getImageData = async () => {
		if (this.state.selectedProjectName == "") {
			return;
		}
		const result = await axios({
			method: "Get",
			url: `${process.env.REACT_APP_API_PATH}/dev/admin/project/task`,
			params: {
				project_name: this.state.selectedProjectName,
				random_one: "true",

			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		const tasks = JSON.parse(result.data.body).tasks;
		console.log(JSON.parse(result.data.body).tasks)
		const leftCount = JSON.parse(result.data.body).left_count;

		//if task item has discard field and if its true, set discarded to true
		tasks.forEach((task) => {
			if (task.discard && task.discard == "true") {
				task.discarded = true;
			}
		});



		//set to state


		this.setState({ data: tasks, leftCount: leftCount, isModalOpen: true, modalImageSrc: this.generateImageUrl(tasks[0]), modalImageSrc2: this.generateResultUrl(tasks[0]) });

	}
	generateImageUrl(item) {

		return `${process.env.REACT_APP_API_PATH}/dev/public/download/image?project_name=${item.project_name}&seq=${item.seq}&type=input`
	}

	generateModelUrl(item) {
		if (item.status != "succeed") {
			return "-";
		}
		return `${process.env.REACT_APP_API_PATH}/dev/public/download/image?project_name=${item.project_name}&seq=${item.seq}&type=model`
	}

	generateResultUrl(item) {

		if (item.status != "succeed") {
			return "-";
		}
		return `${process.env.REACT_APP_API_PATH}/dev/public/download/image?project_name=${item.project_name}&seq=${item.seq}&type=output`
	}
	updateCheckStatus = async (idx, status) => {
		if (status == "reject") {
			this.setState({ isRejctModelOpen: true, rejectTargetIdx: idx });
			return;
		}
		let targetSeq = undefined
		let discardStatus = undefined
		this.setState((prevState) => ({
			data: prevState.data.map((item) => {
				if (item.idx === idx) {
					targetSeq = item.seq

					return {
						...item,
						check_status: status,
					}
				}
				return item;

			}
			)
		}), async () => {

			console.log(targetSeq, discardStatus)
			await axios({
				method: "post",
				url: `${process.env.REACT_APP_API_PATH}/dev/admin/task`,
				data: {
					seq: targetSeq,
					check_status: status
				},
				headers: {
					Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
				},
			});
			this.closeModal();
			this.getImageData();

		});
	};
	updateRejectStatus = async (idx, status) => {

		let targetSeq = undefined
		let discardStatus = undefined
		let rejectReason = this.state.rejectReason
		if (rejectReason == "") {
			alert("제외 사유를 입력해주세요")
			return;
		}
		this.setState((prevState) => ({
			data: prevState.data.map((item) => {
				if (item.idx === idx) {
					targetSeq = item.seq

					return {
						...item,
						check_status: status,
						reject_reason: rejectReason,
					}
				}
				return item;

			},


			),
			isRejctModelOpen: false,
			rejectReason: ""
		}), async () => {

			console.log(targetSeq, discardStatus)
			await axios({
				method: "post",
				url: `${process.env.REACT_APP_API_PATH}/dev/admin/task`,
				data: {
					seq: targetSeq,
					check_status: status,
					reject_reason: rejectReason,
				},
				headers: {
					Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
				},
			});
			this.getImageData();

		});
	};
	// Open modal if Ctrl is pressed when clicking on result image
	handleResultClick = (item) => {

		let url = "";

		this.setState({ isModalOpen: true, modalImageSrc: this.generateImageUrl(item), modalImageSrc2: this.generateResultUrl(item) });

	};

	// Close the modal
	closeModal = () => {
		this.setState({ isModalOpen: false, modalImageSrc: '', isRejctModelOpen: false, rejectTargetIdx: undefined, isShowRejectReasonOpen: false });
	};
	downloadAll = async () => {
		try {
			const s3SignedUrlData = await axios({
				method: 'GET',
				url: `${process.env.REACT_APP_API_PATH}/dev/admin/project/download`,
				params: {
					project_name: this.state.selectedProjectName,

				}

			});
			const url = JSON.parse(s3SignedUrlData.data.body).downloadUrl;
			console.log(url);
			window.open(url, '_blank')
		}
		catch (e) {
			alert("파일 키가 없거나 올바른 요청이 아닙니다.")
			console.log(e)
		}
	};
	exportToExcel = () => {

		const filteredData = this.state.data.filter(item => {
			return item.check_status == "accept";
		});

		// Add new column based on 'seq' for non-discarded rows
		const updatedData = filteredData.map(item => {
			const { idx, seq, discard, category_1, product_id, ...rest } = item;  // Remove the discarded property
			return {

				id: product_id,
				...rest,

				category: category_1,
				url: `${process.env.REACT_APP_API_PATH}/dev/public?seq=${item.seq}` // Custom column based on seq
			};
		});




		// Convert updated data to sheet
		const ws = XLSX.utils.json_to_sheet(updatedData);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Data");

		// Export as Excel file
		XLSX.writeFile(wb, `fssnap_${this.state.selectedProjectName}_${moment().format("YYYY_MM_DD_HH_mm")}.xlsx`);
	};
	handleReRun = async (item) => {

		await axios({
			method: "get",
			url: `${process.env.REACT_APP_API_PATH}/dev/admin/task/rerun`,
			params: {
				seq: item.seq

			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		//clear data that has same seq
		this.setState((prevState) => ({
			data: prevState.data.map((_item) => {


				if (item.seq === _item.seq) {
					let nitem = _item
					nitem.status = "created";
					return {
						...nitem,
						check_status: undefined,
					}
				}
				return _item;

			}
			)
		}));
	};
	onValueChanged = (name, value) => {

		console.log("name:", name, ",value:", value)

		this.setState({
			[name]: value

		}, () => {
			if (name == "selectedProjectName") {
				this.getImageData();
			}
		});

	};
	toggleExcludeEvaluated = () => {
		// Toggle excludeEvaluated state
		this.setState((prevState) => ({
			excludeEvaluated: !prevState.excludeEvaluated
		}));
	};
	render() {
		let filteredData = this.state.data;
		if (this.state.excludeEvaluated) {
			filteredData = this.state.data.filter(item => item.check_status == undefined);
		}

		return (
			<Fragment>
				{this.state.isModalOpen && (
					<div className="modals"
						zIndex={1500}
						style={{

						}} >
						<div className="modal-contents" ref={this.modalRef} style={{ width: "90%", }}>
							<div style={{ width: "100%", fontSize: 25, color: "black", fontWeight: 800, justifyContent: "center", textAlign: "center", marginBottom: "5px" }}>{`남은 숫자:${this.state.leftCount}`}</div>
							<div onClick={() => { this.closeModal() }} style={{
								width: "90%", display: "flex",

								"justify-content": "space-between"
							}}>
								<img style={{
									display: "block",
									"margin-left": "auto",
									"margin-right": "auto",
									"object-fit": "contain",
									"max-height": 800
								}} src={this.state.modalImageSrc} alt="Large version" className="modal-image" />
								<img style={{
									display: "block",
									"margin-left": "auto",
									"margin-right": "auto",
									"object-fit": "contain",
									"max-height": 800
								}} src={this.state.modalImageSrc2} alt="Large version" className="modal-image" />
							</div>
							<div style={{ float: "right" }}>
								<Button
									color="info"
									onClick={() => {
										this.updateCheckStatus(this.state.data[0].idx, "accept");
									}}
								>
									수락
								</Button>
								<Button
									color="danger"
									onClick={() => {
										this.updateCheckStatus(this.state.data[0].idx, "reject");
									}}
								>
									제외
								</Button>
							</div>
						</div>

					</div>)}

				{this.state.isRejctModelOpen && (
					<div className="modals" zIndex={1600}>

						<div className="modal-contents" style={{ "overflow-y": "scroll", }} ref={this.modalRef}>
							<Row>
								<Label
									for="exampleFile"
									sm={2}
								>
									제외 사유
								</Label>
								<textarea
									style={{ height: "250px" }}
									type="text"
									className="form-control"
									placeholder="제외 사유를 입력해주세요"
									value={this.state.rejectReason}
									onChange={(val) => {
										this.onValueChanged("rejectReason", val.target.value);
									}}
								/>
							</Row>
							<Row>

								<Button
									color="danger"

									onClick={() => {
										this.updateRejectStatus(this.state.rejectTargetIdx, "reject");
									}}
								>
									제외
								</Button>
							</Row>
						</div>
					</div>)}
				{this.state.isShowRejectReasonOpen && (
					<div className="modals">

						<div className="modal-contents" style={{ "overflow-y": "scroll", }} ref={this.modalRef}>
							<Row>
								<Label
									for="exampleFile"
									sm={2}
								>
									제외 사유
								</Label>
								<textarea
									style={{ height: "250px" }}
									type="text"
									disabled={true}
									className="form-control"
									placeholder="제외 사유"
									value={this.state.currentRejectReason}

								/>
							</Row>

						</div>
					</div>)}
				<HeaderComponent signOut={this.props.cognito.signOut} setStage={this.props.setStage} stage={this.props.stage}></HeaderComponent>
				<div className="main_contents">
					<Container fluid>
						<Row>
							<div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
								<Col md={4}>

									<Col md={2}>
										<ButtonGroup>
											<Button
												color="info"
												onClick={() => {
													this.getImageData();
												}}
											>
												Refresh
											</Button>
										</ButtonGroup>
									</Col>


								</Col>

								<Col md={4} style={{ alignItems: "center", display: "flex", fontSize: 25, color: "black", fontWeight: 800, justifyContent: "center" }}>
									{`검수(남은 갯수: ${this.state.leftCount})`}
								</Col>
								<Col md={4}>

									<Col md={3}>
										<ButtonGroup>
											<Button
												color="primary"
												onClick={() => {
													this.exportToExcel();
												}}
											>
												Export(Excel)
											</Button>
										</ButtonGroup>
									</Col>

									<Col md={2}>
										프로젝트 선택:
									</Col>
									<Col md={4}>
										<Input value={this.state.selectedProjectName} onChange={(e) => this.onValueChanged("selectedProjectName", e.target.value)} id="selectedProjectName" name="selectedProjectName" type="select">

											{this.state.project_list.map((item) => (
												<option value={item.project_name}>{item.project_name}</option>
											)
											)}


										</Input>
									</Col>
								</Col>
							</div>
							<div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
								<div className="table-container">
									<table>
										<thead>
											<tr>
												<th>Idx</th>
												<th>Product_id</th>
												<th >Filename</th>
												<th>Status</th>
												<th>Original Image</th>
												<th>Model</th>
												<th>Result</th>
												<th>검수상태</th>
												<th>Control</th>
											</tr>
										</thead>
										<tbody>
											{filteredData.map((item) => (
												<tr
													key={item.idx}

													className={item.check_status == "reject" ? 'discarded-row' : ''}
												>
													<td className="table-cell">{item.idx}</td>
													<td className="table-cell">{item.product_id}</td>
													<td className="table-cell" >{item.org_file_name}</td>
													<td className="table-cell">
														{
															item.status == "succeed" ? <span style={{ color: "green", fontWeight: "bold" }}>{"생성 완료"}</span> :
																(item.status == "failed") ? <span style={{ color: "red" }}>{"생성 실패"}</span> : <span style={{ color: "red" }}>{"생성 중"}</span>
														}
													</td>
													<td className="table-cell">
														<img
															style={{ "max-width": "200px", "object-fit": "contain" }}
															src={this.generateImageUrl(item)}
															alt={item.filename}
															className={item.check_status == "reject" ? 'grayed-out' : ''}
															onClick={(e) => this.handleResultClick(item, "input")}
														/>
													</td>
													<td className="table-cell">
														<img
															style={{ "max-width": "200px", "object-fit": "contain" }}
															src={this.generateModelUrl(item)}
															alt={`model-${item.filename}`}
															className={item.check_status == "reject" ? 'grayed-out' : ''}
															onClick={(e) => this.handleResultClick(item, "model")}
														/>
													</td>

													<td className="table-cell">
														<img
															src={this.generateResultUrl(item)}
															alt={`result-${item.filename}`}
															className={item.check_status == "reject" ? 'grayed-out' : ''}
															onClick={(e) => this.handleResultClick(item, "result")}  // Handle Ctrl + Click
														/>
													</td>
													<td className="discard-status" >{
														(item.check_status == "accept") ? <span style={{ color: "green", fontWeight: "bold" }}>{"수락"}</span> : (item.check_status == "reject") ? <span style={{ color: "red" }}>{"제외"}<br></br><Button
															color="info"
															onClick={() => {
																this.setState({ isShowRejectReasonOpen: true, currentRejectReason: item.reject_reason });
															}}
														>
															사유보기
														</Button></span> : <span style={{ color: "red", fontWeight: "bold" }}>{"[미선택]"}</span>

													}</td>
													<td className="table-cell">
														<Button
															color="info"
															onClick={() => {
																this.updateCheckStatus(item.idx, "accept");
															}}
														>
															수락
														</Button>
														<br></br>
														{
															(item.check_status != "reject") ? (
																<Button
																	color="danger"
																	onClick={() => {
																		this.updateCheckStatus(item.idx, "reject");
																	}}
																>
																	제외
																</Button>) : ""}
														<br></br>
														<Button
															color="warning"
															onClick={() => {
																this.handleReRun(item);
															}}
														>
															Rerun
														</Button>
													</td>
												</tr>
											))}
										</tbody>
									</table>


								</div>
							</div>
						</Row>
					</Container>
				</div>
			</Fragment >
		);
	}
}

export default CheckPage;
